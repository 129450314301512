import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, H3, Popup, Row, Csku, Dropzone } from '@commonsku/styles';

import { closePopup, createSpinnerPopup } from '../../../actions/popup';
import { createUploadGuestFile } from '../../../actions/file';
import { createFinalizeUpdateArtwork } from '../../../actions/artwork';
import { getImageSrc } from '../../../utils';
import { BASE_ZINDEX } from '../../../popup-factory';
import Img from '../../Img';

const SelectShopArtworkPopup = ({
  artwork_files,
  onClosePopup,
  onUploadImage,
  onSelectArtwork,
  onRemoveArtworkImage,
  index,
  artwork_image=null
}) => {
  const zIndex = BASE_ZINDEX + index + 10;
  const selectedArtworkImageId = get(artwork_image, ['file_id']);

  return (
    <Csku
      as={Popup}
      width={'auto'}
      height={'auto'}
      padding={'12px'}
      style={{
        md: {
          maxWidth: 650,
        },
        xs: {
          minWidth: '90%',
        },
      }}
      zIndex={zIndex}
      overlayZIndex={zIndex-10}
      title={'Select Artwork'}
      onClose={onClosePopup}
      header={<Row className='popup-header' justify="space-between" style={{ marginBottom: 14 }}>
        <Col xs sm={4}>
          <H3>Select Artwork</H3>
        </Col>
        <Col xs sm={8} end>
          <Button size='small' variant='primary' style={{ marginRight: 7, marginBottom: 7 }}>
            <Dropzone style={{ border: 'none' }} onDrop={onUploadImage}>
              <><i className="fi-upload" style={{ fontSize: '0.9em' }}></i>&nbsp;&nbsp;Upload Image</>
            </Dropzone>
          </Button>
          {selectedArtworkImageId ? <Button
            size='small'
            variant='primary'
            style={{ marginRight: 7, marginBottom: 7 }}
            onClick={() => onRemoveArtworkImage(selectedArtworkImageId)}
          >
            Remove Artwork
          </Button> : null}
          <Button size='small' variant='secondary' onClick={onClosePopup}>Close</Button>
        </Col>
      </Row>}
    >
      <Row>
        <Col xs>
          {artwork_files.map(a =>
            <div key={a.file_id} className="small-6 medium-4 large-3 columns end" onClick={() => onSelectArtwork(a.file_id)} style={{ cursor: 'pointer' }}>
              <Img src={getImageSrc(a)} />
            </div>)}
        </Col>
      </Row>
    </Csku>
  );
};

const mapStateToProps = (state, ownProps) => ({
  artwork_files: Object.values(state.entities.files).filter(f => 'GUEST' === f.parent_id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onUploadImage: image => Promise.resolve(
    dispatch(createSpinnerPopup('Uploading...'))
  ).then(
    () => dispatch(createUploadGuestFile('GUEST', 'GUEST', image[0], ownProps.tenant_id)).then(
      action => dispatch(createFinalizeUpdateArtwork({ artwork_id: ownProps.artwork_id, artwork_file_id: action.payload.id }))
    ),
    () => dispatch(closePopup())
  ).then(
    () => {
      dispatch(closePopup());
      return dispatch(closePopup());
    }
  ),
  onSelectArtwork: file_id => {
    dispatch(createFinalizeUpdateArtwork({ artwork_id: ownProps.artwork_id, artwork_file_id: file_id }));
    return dispatch(closePopup());
  },
  onRemoveArtworkImage: file_id => {
    dispatch(createFinalizeUpdateArtwork({ artwork_id: ownProps.artwork_id, artwork_file_id: '' }));
    return dispatch(closePopup());
  },
});

const ConnectedSelectShopArtworkPopup = connect(mapStateToProps, mapDispatchToProps)(SelectShopArtworkPopup);
export default ConnectedSelectShopArtworkPopup;
